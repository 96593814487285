import { ActionFunctionArgs, redirect } from "@remix-run/cloudflare";
import { Link, useSearchParams } from "@remix-run/react";
import { withZod } from "@remix-validated-form/with-zod";
import { z } from "zod";
import { ValidatedForm, validationError } from "remix-validated-form";
import { authenticator } from "@/lib/auth.server";
import { authSessionStorage } from "@/lib/cookie.server";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

const validator = withZod(
  z.object({
    email: z
      .string()
      .min(1, { message: "Email is required" })
      .email("Must be a valid email"),
    password: z.string().min(8, { message: "Password is too short" })
  })
);

export async function action({ request, context }: ActionFunctionArgs) {
  const formData = await request.clone().formData();
  const result = await validator.validate(formData);
  if (result.error) {
    throw validationError(result.error);
  }

  const cookieHeader = request.headers.get("Cookie");
  let user = null;
  try {
    user = await authenticator.authenticate("form", request, {
      context: {
        formData,
        request,
        context
      }
    });
  } catch (e) {
    console.log(e);
  }
  if (user) {
    if (!user.confirmed) {
      return validationError({
        fieldErrors: {
          password: "Please confirm your email address first"
        }
      });
    }
    const session = await authSessionStorage.getSession(cookieHeader);

    session.set("user", user);
    session.set("strategy", "form");
    return redirect("/app", {
      headers: {
        "Set-Cookie": await authSessionStorage.commitSession(session)
      }
    });
  } else {
    return validationError({
      fieldErrors: {
        password: "Incorrect login credentials"
      }
    });
  }
}

export default function Index() {
  const [params] = useSearchParams();

  return (
    <div
      className="min-h-screen relative md:flex bg-gray-900">
      <div className="hidden md:block relative md:flex-grow-[1.618] w-0">
        <div className="absolute inset-0 pricing-bg" />
        <div className="relative z-10 flex items-center justify-center text-white h-full p-8">
          <h1 className="text-4xl font-bold text-white max-w-[40rem] text-center leading-[1.35]">
            Start Generating Targeted Traffic that Moves the Needle
          </h1>
        </div>
      </div>
      <div className="sm:p-16 p-6 pt-12 md:flex-grow md:w-0 flex items-center justify-center bg-white">
        <div className="flex w-full flex-col max-w-sm gap-8">
          <div className="text-center">
            <h1 className="text-3xl font-bold tracking-tight">
              Login to Your Account
            </h1>
          </div>
          <ValidatedForm validator={validator} method="post" defaultValues={{
            email: params.get("email") ?? ""
          }} className="">
            <div className="flex flex-col gap-4">
              <Input name="email" label="Email" />
              <Input name="password" label="Password" type="password" />
              <Button submit className="mt-4 flex">
                Sign In
              </Button>
            </div>
            <div className="flex mt-2 gap-2 items-center justify-center">
              <Link
                className="text-sm underline underline-offset-4 text-center block"
                to="/signup"
              >
                Sign up instead
              </Link>
              <span className="text-gray-600 text-sm">•</span>
              <Link
                className="text-sm underline underline-offset-4 text-center block"
                to="/reset-password"
              >
                Reset password
              </Link>
            </div>
          </ValidatedForm>
        </div>
      </div>
    </div>
  );
}
